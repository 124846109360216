<template>
  <div class="contact">
    <div class="img-header">
      <img src="../../assets/images/contact-header.png" alt="" />
    </div>
    <div class="parent">
      <page-header
        :tabs="tabs"
        name="联系我们"
        :active-tab="activeTab"
        @tab-change="tabChange"
      ></page-header>
      <div class="padding">
        <a href='https://home.myyscm.com/ccsy/' target='_blank'>招标采购</a>
      </div>
    </div>
    
    <div class="contact-content">
      <property-title
        :backgroundTitle="backgroundTitle"
        :title="title"
        :suggest="suggestText"
      />

      <div class="map" id="map"></div>
      <div class="city">
        <div
          class="city-item"
          v-for="item in cities"
          :key="item.id"
          :class="{ active: activeId === item.id }"
          @click="changeCity(item)"
        >
          {{ item.city }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetCities } from "../../api/index";
import PageHeader from "../../components/PageHeader/PageHeader.vue";
import PropertyTitle from "../../components/common/PropertyTitle";
export default {
  metaInfo: {
    title: '爱情地产',
    meta: [{
      name: 'keyWords',
      content: '爱情地产'
    },
    {
      name: 'description',
      content: ''
    }]
  },
  data() {
    return {
      activeId: "1",
      tabs: ["各地公司"],
      activeTab: "各地公司",
      backgroundTitle: "ADDRESS",
      title: "集团总部",
      suggestText:
        "<p>地址：北京市丰台区广安路9号国投财富广场2号楼18层</p><p>联系电话：010-6336 6673</p>",
      cities: [],
    };
  },
  components: {
    PageHeader,
    PropertyTitle,
  },
  watch: {
    $route: {
      immediate: true,
      handler(v) {
        if (v && v.meta.name) {
          this.activeTab = v.meta.name;
        }
      },
    },
  },
  methods: {
    initData() {
      apiGetCities()
        .then((data) => {
          if (data.data.status == 1) {
            this.cities = data.data.relation;
          }
        })
        .then(() => {
          this.init(
            this.cities[0].city,
            this.cities[0].coordx,
            this.cities[0].coordy
          );
        });
    },
    tabChange(tab) {
      this.activeTab = tab;
    },
    changeCity(val) {
      this.activeId = val.id;
      this.init(val.city, val.coordx, val.coordy);
    },
    init(city, x, y) {
      var map = new BMapGL.Map("map");
      // 创建地图实例
      var point = new BMapGL.Point(x, y);
      // 创建点坐标

      var marker = new BMapGL.Marker(point); // 创建标注

      if (city === "北京") {
        map.addOverlay(marker);
      }
      map.centerAndZoom(point, 19);
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style scoped lang="less">
.contact {
  width: 100%;
  height: 100%;
  padding-top: 140px;
  .img-header {
    width: 100%;
    height: 200px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .contact-content {
    width: 100%;
    position: relative;
    .map {
      width: 100%;
      height: 622px;
      border: 1px dashed #eee;
      margin-top: 270px;
    }
    .city {
      position: absolute;
      width: 1440px;
      height: 226px;
      box-shadow: 0px 4px 14px 7px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      top: 370px;
      left: 50%;
      margin-left: -720px;
      background-color: #fff;
      padding: 57px 105px;
      .city-item {
        font-family: "苹方-简 常规体";
        src: url("../../assets/fonts/PINGFANG MEDIUM.TTF");
        float: left;
        width: 56px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        font-size: 28px;
        color: #000;
        margin-left: 70px;
        margin-bottom: 32px;
        &:first-child {
          margin-left: 0;
        }
        &:nth-child(11) {
          margin-left: 0;
        }
      }
      .active {
        color: #e95506;
      }
    }
  }
}
.parent{
  position: relative;
}
.padding a{
  text-decoration: none;
  font-size: 20px;
  line-height: 28px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  position: absolute;
  right: 280px;
  top: 50%;
  transform: translateY(-50%);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
